export default function reducer(state={
		client: [],
		clients: [],
		products: [],
		last_year_products: [],
		client_address: [],
		brand_clients: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_CLIENTS": {
			return {...state, fetching: true}
		}
		case "FETCH_CLIENTS_BRAND_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CLIENTS_BRAND_FULFILLED": {
			return {...state, fetching: false, fetched: true, brand_clients: action.payload.clients}
		}
		case "FETCH_CLIENTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CLIENTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, clients: action.payload.clients}
		}
		case "FETCH_CLIENTS_ADDRESS_FULFILLED": {
			return {...state, fetching: false, fetched: true, client_address: action.payload.client_address}
		}
		case "CREATE_CLIENT": {
			return {...state, fetching: true}
		}
		case "CREATE_CLIENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_CLIENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, client: action.payload.client, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_CLIENT": {
			return {...state, fetching: true}
		}
		case "FETCH_CLIENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CLIENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, client: action.payload.client, products: action.payload.products, last_year_products: action.payload.last_year_products}
		}
		case "CLEAR_CLIENT": {
			return {...state, fetching: false, fetched: true, client: [], products: [], last_year_products: []}
		}
		case "CLEAR_CLIENT_PRODUCTS": {
			return {...state, products: [], last_year_products: []}
		}
		case "UPDATE_CLIENT": {
			return {...state, fetching: true}
		}
		case "UPDATE_CLIENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_CLIENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, client: action.payload.client}
		}
		case "DELETE_CLIENT": {
			return {...state, fetching: true}
		}
		case "DELETE_CLIENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_CLIENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, clients: action.payload.clients}
		}
		default: {
        	break;
		}
	}

	return state
}